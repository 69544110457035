const exhibitionOptions = {
  whyExhibit: {
    title: "WHY EXHIBIT?",
    description:
      "Purchasing an exhibition booth at the B2G and B2B defense industry event offers participants a strategic advantage by enhancing visibility, providing a dedicated space for meetings, and facilitating interactive engagement with attendees. Booths not only help in generating high-quality leads and building relationships but also allow companies to showcase their innovations and gather valuable market insights. By investing in a booth, participants can achieve a higher return on investment and play a significant role in the success of the event.",
    benefitsTitle: "Here are the benefits:",
    benefits: [
      "Enhanced Visibility and Brand Presence",
      "Prominent Showcase",
      "Private Meetings",
      "Attracting Visitors",
      "Interactive Engagement",
      "Showcasing Innovation",
      "Live Demonstrations",
      "Highlighting Expertise",
      "Distribute Promotional Materials",
      "Trackable Interactions",
      "Quantifiable Benefits",
    ],
    additionalDescription:
      "In case other participants request for a meeting with you, the meeting will be held at your booth. You can then present your demonstration and products at your own booth.",
  },
  exhibitionOptions: {
    title: "The following exhibiting options are available",
    bookButtonText: "Book Booth Now",
    options: {
      C1: {
        id: "C1",
        title: "Eco Booth 4 sqm",
        button: "Book Booth Now",
        features: [
          "Your name is stamped on the map of the event showing location of your stand.",
          "Your B2B meetings requested by other participants can be held with the participants at your stand.",
          "One Casual Visitor Pass.",
          "Fascia name, one table, 3 chairs, carpet, 2 lights dustbin, electric outlet provided",
        ],
        description: "",
      },
      C2: {
        id: "C2",
        title: "Pride Booth 6 sqm",
        button: "Book Booth Now",
        features: [
          "Your name is stamped on the map of the event showing location of your stand.",
          "Your B2B meetings requested by other participants can be held with the participants at your stand.",
          "Two Casual Visitor Passes.",
          "Logo and Fascia name, two tables, 4 chairs, carpet, 3 lights, dustbin, electric outlet provided.",
        ],
        description: "",
      },
      C3: {
        id: "C3",
        title: "Premium Booth 12 sqm",
        button: "Book Booth Now",
        features: [
          "Your name is stamped on the map of the event showing location of your stand.",
          "Your B2B meetings requested by other participants can be held with the participants at your stand.",
          "Three Casual Visitor Passes.",
          "Logo and Fascia name, two tables, 6 chairs, carpet, 6 lights, dustbin, magazine rack, electric outlet provided.",
        ],
        description: "",
      },
      C4: {
        id: "C4",
        title: "Additional Furnishings",
        button: "Book Booth Now",
        description:
          "like Magazine Rack, Chairs, sofas, showcase, LCD with stand, Flex Banners to cover walls are available. Order separately from Exhibition Services Catalogue",
        features: [],
      },
    },
  },
  exhibitionOrder: {
    titles: {
      ecoBooth: "Eco Booth 4sqm",
      prideBooth: "Pride Booth 6 sqm",
      premiumBooth: "Premium Booth 12 sqm",
    },
    details: {
      fasciaName: "Fascia name",
      maxCharacters: "(Max 30 characters)",
      uploadLogo: "Upload Company’s logo",
      uploadLogoDescription: "Upload Company’s logo Here",
      uploadLogoSize: "(16:9 ratio, high-res JPG, PNG max 25 mb)",
      boothTitleDescription:
        "Please fill in the details below to fulfill your order:",
      casualVisitorTitle:
        "Please provide details of Casual Visitor to be registered. (Indian national only).",
      casualVisitorForms: [
        {
          label: "Casual Visitor1",
          name: "Name",
          email: "Email",
          mobile: "Mob No.",
        },
        {
          label: "Casual Visitor2",
          name: "Name",
          email: "Email",
          mobile: "Mob No.",
        },
      ],
      doItLaterButtonText: "Save & Exit",
      submitButtonText: "Submit",
      note: {
        boldText: "Note: ",
        description:
          "You may complete the requirement from your profile page later.",
      },
    },
  },
}
export default exhibitionOptions