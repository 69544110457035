import React from 'react';
import ScheduleRow from './ScheduleRow';

const CommonScheduleTable = ({ title, data = [], isParallel = false }) => {
    // Determine which headers to use based on whether it's a parallel program
    const headers = isParallel
        ? ['time', 'activity', 'speaker_presenter', 'location', 'attend']
        : ['time', 'activity', 'location'];

    return (
        <div className='bg-white shadow-md rounded-lg space-y-3'>
            <div className='text-red bg-bgred text-center font-semibold text-lg p-2'>{title}</div>
            <div className="divide-y divide-gray-200">
                <div className="py-2 px-4 bg-footerblue text-sm text-white flex gap-3 font-bold">
                    {headers.map((header, index) => (
                        <div key={index} className={`uppercase ${header === 'time' ? 'w-[10%]' : header === 'activity' ? 'w-[65%]' : 'w-[15%]'}`}>
                            {header.replace('_', '/')}
                        </div>
                    ))}
                </div>
                {data.map((item, index) => (
                    <ScheduleRow key={index} index={index} {...item} className="flex gap-3" isParallel={isParallel} />
                ))}
            </div>
        </div>
    );
}

export default CommonScheduleTable;
