const mainBusinessActivities = [
  "3D Printing",
  "Academia",
  "Advanced Materials",
  "Aeroengines",
  "Aerospace Engineering",
  "Aerospace Equipment",
  "Aerospace",
  "Air Defence Systems",
  "Air Materiel",
  "Air Transportation",
  "Aircraft industrial equipment",
  "Aircraft interiors",
  "Aircraft spares",
  "Airframe Structural",
  "Airport equipment",
  "Alloys and composites",
  "Ammunition & Explosives",
  "Ammunition",
  "An-32 Aircraft",
  "Armament",
  "Armoured Vehicles",
  "Artificial Intelligence",
  "Artillery",
  "Associations and Organisations",
  "Automation",
  "Automobiles Equipment",
  "Automotive parts & systems",
  "Aviation",
  "Avionics",
  "AWACS",
  "Ballistic Protection",
  "Catering Services & Equipment",
  "Chemicals Manufacturer",
  "Civil aircrafts",
  "Clothing special",
  "Cockpit equipment",
  "Combat Engineering Equipment",
  "Combat Vehicles",
  "Communications Systems",
  "Composites & Alloys",
  "Computer and Electronic Components",
  "Consultants and Special Services",
  "Cyber Services",
  "Design and prototyping",
  "Distributors/Suppliers",
  "Early Warning Systems",
  "Electrical Systems and Components Manufacturer",
  "Electrical Systems and Components",
  "Electronic Systems",
  "Electronic Warfare",
  "Electro-optics",
  "Emerging Technologies",
  "Energy renewable hybrid",
  "Engine Manufacturer",
  "Engineering",
  "Engines",
  "Field Formations",
  "Fighter Aircraft Rafael",
  "Fighter Aircraft Su-30 MiG-29 Mirage LCA",
  "Fighter Aircraft",
  "Financial Services",
  "Fuels & Oils",
  "Furnishing & Supplies",
  "General aviation",
  "Government Supplier",
  "Ground Communication Systems",
  "Ground Support Equipment",
  "GSQR",
  "Hardware Manufacturer",
  "Health & Hygiene",
  "Helicopters",
  "IL76 IL78 AWACS Aircraft",
  "Impot Substitution",
  "Incubation",
  "Indigenisation",
  "Industry Engagement",
  "Industry Schemes",
  "Infantry equipment",
  "Information Technology",
  "Innovations",
  "Innovators",
  "Insurance Leasing Finance & Sales",
  "Intelligence",
  "Investors",
  "Light Combat Aircraft",
  "Logistics",
  "Maintenance",
  "Make 1 2 3 projects",
  "Material Handling Equipment",
  "Materials Manufacturer",
  "Mechanical Components",
  "Medical Care",
  "Microlight",
  "MiG29K Fighter Aircraft",
  "Military Aircraft",
  "Military Weapon Systems",
  "Missiles",
  "Modifications/Completions/Overhauls",
  "MRO Maintenance Repairs Overhaul",
  "Naval Aviation",
  "Naval Craft Manufacturer",
  "Naval Crafts and Systems",
  "Naval Systems",
  "Night Vision Equipment",
  "Nuclear submarines",
  "OEMs",
  "Overhaul and Rotables",
  "Parachutes",
  "Paramilitary/Police equipment",
  "Perimeter Protection",
  "Polymers",
  "Power supplies",
  "Prime Contractor",
  "Quality Assurance",
  "Radars",
  "Regulating Agencies",
  "Repairs",
  "Research & Development",
  "Robotics",
  "Rotorcrafts/Helicopters",
  "Safety Equipment",
  "Satellites",
  "Sensors",
  "Ships and submarines,'",
  "Software Automation Systems and Services",
  "Soldier systems",
  "Space Equipment",
  "Space launch systems & equipment",
  "Spares Manufacturer",
  "Special Materials Memory electronics",
  "SPVs",
  "Standardisation",
  "Startups",
  "Su-30MKI Fighter Aircraft",
  "Surveillance systems",
  "Survey",
  "System and Services",
  "Systems Manufacturer",
  "Technology Development Fund",
  "Technology Management",
  "Test Eqpt",
  "Test Equipment Manufacturer",
  "Tools/Machines Manufacturer",
  "Trainer Aircraft",
  "Training Services",
  "Transport aircraft",
  "Transport Aircraft An-32",
  "Transport Aircraft Avro PC-7",
  "Transport aircraft",
  "Trials",
  "Underwater systems",
  "Unmanned Systems",
  "Unmanned systems",
  "Vehicles special",
  "Venture capital",
  "Weapon Systems Manufacturer",
];

const mainBusinessProducts = [
  "3D PRINTED SPARE PARTS",
  "3D PRINTING ADDITIVE MFG",
  "ABLATIVE MATERIALS",
  "ABRASIVES",
  "ACCELEROMETERS",
  "ACCUMULATORS",
  "ACID CLEANING",
  "ACMI",
  "ACOUSTICAL EQPT",
  "Actuators",
  "ACTUATORS",
  "ADAPTERS: CABLE & HOSE",
  "ADDITIVE MANUFACTURING",
  "ADDITIVES",
  "ADHESIVE",
  "ADVANCED MATERIALS",
  "AERIAL APPLICATING",
  "AERO ENGINES",
  "AIR CONDITIONING",
  "AIR BLASTING",
  "AIR DATA COMPUTER",
  "AIR DEFENCE SYSTEMS",
  "AIR FRAMES",
  "AIR REFUELING",
  "AIR START SYSTEMS",
  "AIR TRAFFIC CONTROL",
  "AIR TRANSPORTATION",
  "AIRBORNE ARMAMENTS",
  "AIRBORNE DEVELOPMENT SYSTEMS",
  "AIRBORNE EQPT",
  "AIRBORNE FURNISHING",
  "AIRBORNE STRUCTURAL",
  "AIRCRAFT",
  "AIRCRAFT CANNONS",
  "AIRCRAFT CONVERSIONS",
  "AIRCRAFT EQPT & PARTS",
  "Aircraft sensors",
  "aircraft speed vector",
  "AIRCRAFT STRUCTURAL COMPONENTS",
  "AIRFIELD AND AIRPORT EQPT",
  "AIRFRAME MANUFACTURER",
  "AIRFRAMES",
  "AIRPORT DESIGN & CONSTRUCTION",
  "AIRPORT MONITOR AND CONTROL",
  "AIRSHIPS AND BALLOONS",
  "ALARM SYSTEMS",
  "ALIGNMENT EQPT",
  "ALKALINE CLEANING",
  "ALTERNATORS",
  "ALTIMETERS",
  "ALUMINUM ALLOYS",
  "AMBULANCES",
  "AMMETERS",
  "AMMUNITION",
  "AMMUNITION MANUFACTURER",
  "AMPLIFIERS",
  "ANALYZERS",
  "ANEMOMETERS",
  "ANNUNCIATORS",
  "ANODIZING MATERIAL & SERVICES",
  "ANTENNAS & MASTS",
  "ANTI-FOGGING COMPOUND",
  "ANTI-FREEZE COOLANTS",
  "ANTI-ICING CHEMICALS & EQPT",
  "ANTI-RIOT EQPT",
  "ANTI-SKID SYSTEMS",
  "ANTI-STATIC MATERIALS",
  "ANTI-VIBRATIONS EQPT",
  "APC OVERHAUL/UPGRADING",
  "ARMATURES: REWINDING",
  "ARMOUR",
  "ARMOURED FIGHTING VEHICLES",
  "Arrester Barrier",
  "ARRESTING SYSTEMS",
  "ARTILLERY",
  "ASBESTOS",
  "ATTENUATORS",
  "AUDIO EQPT",
  "AUDIOMETRIC EQPT",
  "AUTO-PILOTS",
  "AUTOCLAVS",
  "AUTOCOLLIMATORS",
  "AUTOMATIC FLIGHT CONTROL SYSTEMS",
  "AUTOMATION SYSTEMS",
  "AUXILIARY POWER UNITS",
  "AVIATION CONSULTANTS",
  "AVIONICS EQPT",
  "BALANCING EQPT",
  "BALLISTIC PROTECTION",
  "BALLSCREWS",
  "BAR CODE READING EQPT",
  "BAROMETERS & BAROGRAPHS",
  "BATTERIES & CHARGING SYSTEMS",
  "BEACONS",
  "BEARINGS",
  "BELLOWS",
  "BIOLOGICAL MATERIALS PROCESSING",
  "BLACK BOX",
  "BLADDERS",
  "BLADES",
  "BLAST FENCING",
  "BLAST FINISHING",
  "BLEED AIR SYSTEMS",
  "BLOWERS",
  "BOATS/CRAFT",
  "BODY ARMOUR",
  "Bolts",
  "BONDING ACCESSORIES",
  "BOOMS",
  "BOOSTERS",
  "BORESCOPES",
  "BORESIGHTS",
  "BORING EQPT",
  "BRACKETS",
  "BRAIDING",
  "BRAKES & LININGS",
  "BRAZING MATERIALS",
  "BREATHING EQPT",
  "BROACHING EQPT",
  "BULLETPROOF VESTS",
  "BUS BARS",
  "BUSHINGS",
  "CABLES",
  "CAD/CAM SYSTEMS",
  "CALIBRATION",
  "CALORIMETERS",
  "CAMERAS",
  "CAMOUFLAGE",
  "CANVAS",
  "CAPACITORS",
  "Capacitors",
  "CARBON FIBRE",
  "CARBURETTORS",
  "CARGO HANDLING EQPT",
  "CARTRIDGES & PROPELLANTS",
  "CASTINGS & FORGINGS",
  "CATAPULTS",
  "CATERING EQPT",
  "CATERING SERVICES",
  "CBRN",
  "CENTRIFUGES",
  "CERAMICS",
  "CERTIFICATION SERVICES",
  "CHAINS",
  "CHEMICAL AGENTS: DETECTORS",
  "CHEMICAL ANALYSIS EQPT",
  "CHEMICAL WASTE TREATMENT",
  "CHEMICALS",
  "CHEMICALS MANUFACTURER",
  "CHLORINATED SOLVENTS",
  "CHOCKS",
  "CHRONOMETERS",
  "CIRCUIT BREAKERS",
  "CIRCUITS",
  "CLAMPS",
  "CLEAN ROOMS SERVICE",
  "CLEANING MATERIALS",
  "CLIPS",
  "CLOCKS AND CHRONOGRAPHS",
  "CLOSE-IN WEAPON SYSTEMS",
  "CLOTH & CLOTHING",
  "COATING REMOVAL",
  "COATINGS",
  "COAXIAL COMPONENTS",
  "CODING EQPT",
  "COILS & COIL WINDING EQPT",
  "COLLIMATORS",
  "COLLISION AVOIDANCE SYSTEMS",
  "COMBUSTION CHAMBERS",
  "COMBUSTION CONTROLS",
  "COMMAND AND CONTROL SYSTEMS",
  "Communications",
  "COMMUNICATIONS SYSTEMS",
  "COMMUTATORS: ELECTRICAL",
  "COMPARATORS",
  "COMPASSES",
  "COMPONENTS MANUFACTURER",
  "COMPOSITE MATERIALS",
  "COMPRESSORS",
  "COMPUTER AND ELECTRONIC",
  "COMPUTER-AIDED SYSTEMS",
  "COMPUTER-INTEGATED MANUFACTURING",
  "COMPUTERS AND COMPONENTS",
  "COMPUTERS: NAVIGATION: AVIONIC",
  "COMPUTERS: SOFTWARE",
  "CONDENSERS",
  "CONDITIONERS: SIGNAL",
  "CONDUITS",
  "CONNECTORS & ACCESSORIES",
  "CONSOLES",
  "CONSTANT-SPEED DRIVES",
  "CONSULTANTS AND SPECIAL SERVICES",
  "CONSULTANTS: AVIATION",
  "CONSULTANTS: TECHNICAL",
  "CONSUMABLES",
  "CONTAMINATION MONITORS",
  "Control & Monitoring System",
  "CONTROL SYSTEMS",
  "CONTROLS: PANEL",
  "CONVERSIONS",
  "CONVERTERS",
  "CONVEYOR SYSTEMS",
  "COOLANTS & REFRIGERANTS",
  "COOLING EQPTS",
  "COPPER AND COPPER ALLOYS",
  "CORK MATERIAL FABRICATIONS",
  "CORROSION INHIBITORS",
  "CORROSION TEST EQPT",
  "COUNTERMEASURES DISPENSING SYSTEMS",
  "COUNTERS",
  "COUNTERWEIGHTS",
  "COUPLINGS",
  "CRANES",
  "CRANKCASES: ENGINE",
  "CRASH BARRIERS",
  "CRASH/FIRE/RESCUE VEHICLES",
  "CRYOGENIC EQPT",
  "CRYOSTATS",
  "CUPOLAS AND TURRETS",
  "CUTTING TOOLS & EQPT",
  "CYLINDERS",
  "DAMPERS",
  "DATA ACQUISITION EQPT",
  "Data Acquisition Eqpt",
  "DATA ANALYSIS & PROCESSING",
  "DATA BUS",
  "DATA COMMUNICATIONS",
  "DATA CONVERSION DEVICES",
  "DE-ICING AND ANTI-ICING",
  "DEBURRING",
  "DECODERS",
  "DECONTAMINATION SHOWER",
  "DEFENCE EXHIBITION",
  "DEFENCE EXPORTER",
  "DEFENCE TECHNOLOGIES",
  "DEFENCE: COUNTER-SUPPORT MEASURES",
  "DEGREASING CHEMICALS",
  "DEHUMIDIFYING EQPT & CHEMICALS",
  "DEHYDRATING",
  "DEMAGNETIZING EQPT",
  "DENSITOMETERS",
  "DESIGN SERVICES",
  "DETECTION & CLEARING OF EXPLOSIVES",
  "DETECTORS",
  "DETERGENTS",
  "DIES",
  "DIESEL ENGINES",
  "DIFFUSERS",
  "DIGITAL EQPT",
  "Digital Eqpt",
  "DIGITIZING EQPT",
  "DINGHIES",
  "DIODES: SEMICONDUCTORS",
  "DIRECTION FINDERS",
  "DISCHARGERS",
  "DISCS & DIAPHRAGMS",
  "DISPLAY SYSTEMS",
  "DISTANCE MEASURING EQPT",
  "DISTRIBUTORS",
  "Diving Equipment",
  "DRILLING EQPT",
  "DRIVERS",
  "DRIVES DRONES",
  "DUCTS",
  "DYNAMOMETERS",
  "EARPHONES",
  "EDUCATION RESEARCH AND CONSULTANCY",
  "EJECTION SYSTEMS",
  "ELASTOMERS",
  "ELECTOFORMS",
  "ELECTRIC GENERATORS AND MOTORS",
  "ELECTRIC POWER ENERGY CONVERSION",
  "ELECTRIC SOLENOIDS AND RELAYS",
  "ELECTRIC TRANSFORMERS REGULATORS.",
  "ELECTRICAL COMPONENTS AND EQPT",
  "ELECTRICAL SYSTEMS",
  "ELECTRO OPTIC EQPT",
  "ELECTROFORMING",
  "ELECTROLESS PLATING",
  "ELECTROMAGNETIC CONTROL EQPT",
  "ELECTROMAGNETIC PULSE",
  "ELECTROMECHANICAL ASSEMBLY",
  "ELECTRON BEAM EQPT",
  "ELECTRONIC CHART SYSTEMS",
  "ELECTRONIC COMPONENTS",
  "ELECTRONIC EQPT",
  "ELECTRONIC SECURITY SYSTEMS",
  "Electronic Warfare",
  "ELEVATORS/LIFTS",
  "EMC SHIELDING DEVICES",
  "EMERGENCY LIGHTING SYSTEMS",
  "EMERGENCY LOCATORS",
  "EMI/RFI COATINGS",
  "ENCAPSULATING",
  "ENCODERS/DECODERS",
  "ENCRYPTION SYSTEMS",
  "ENGINE BAFFLES",
  "ENGINE COMPONENTS & PARTS",
  "ENGINE MANUFACTURER",
  "ENGINE MONITORING",
  "ENGINE SENSORS",
  "ENGINEERING SERVICES",
  "ENGINEERING VEHICLES",
  "ENGINES",
  "ENVIRONMENTAL CONTROL SYSTEMS",
  "EPOXY ETCHING",
  "EQUIPMENT MANUFACTURER",
  "EVACUATION SYSTEMS & EQPT",
  "EVAPORATORS",
  "EVENTS ORGANISER",
  "EXCITERS",
  "EXHAUST SYSTEMS",
  "EXIBITION SERVICES/ORGANISER",
  "EXPANDERS: CRYOGENIC",
  "EXPENDABLES",
  "EXPLOSIVES",
  "EXPLOSIVES DISPOSAL EQPT",
  "EXTRUSIONS",
  "EYE PROTECTION",
  "FABRICATING SERVICES",
  "Fabrics",
  "FABRICS AND TEXTILES",
  "FAC Kits",
  "FAILURE INVESTIGATION",
  "FAIRINGS",
  "FANS AND BLOWERS",
  "FAST PATROL BOATS",
  "FASTENERS",
  "FASTENING TOOLS/EQPT",
  "FATIGUE TESTING",
  "FELT",
  "FENCING FERRULES",
  "FIBRE GLASS",
  "FIBRE OPTIC GYROS",
  "FIBRE OPTICS",
  "FIBREGLASS BOATS",
  "FIELD LAUNDRIES",
  "FIELD CAMP SYSTEMS",
  "FIELD HOSPITALS",
  "FIGHTING VEHICLES",
  "FILAMENT WINDING",
  "FILM AND VIDEO",
  "Filters",
  "FILTERS",
  "FILTRATION EQPT",
  "FIRE FIGHTING EQPT",
  "FIRE PREVENTION & CONTROL",
  "FIREARMS: SIMULAATOR AND TRAINING",
  "FIRST AID EQPT",
  "FLANGES",
  "FLAPS",
  "FLARES",
  "FLASHERS",
  "FLEXIBLE SHAFTS",
  "FLIGHT CONTROL EQPT/SYSTEMS",
  "FLIGHT DIRECTORS",
  "FLIGHT INFORMATION SYSTEMS",
  "FLIGHT INSTRUMENT SYSTEMS",
  "FLIGHT MANAGEMENT SYSTEMS",
  "FLIGHT NAVIGATION EQPT",
  "FLIGHT RECORDERS",
  "FLIGHT SIMULATORS AND TRAINERS",
  "FLIGHT TESTING SERVICES",
  "FLIR SYSTEMS",
  "FLOATS",
  "FLOODLIGHTS",
  "FLOTATION GEAR",
  "FLUID HANDLING EQPT",
  "FLUID POWER EQPT",
  "FLYING TRAINING EQPT",
  "FOAM CORE",
  "FOILS",
  "FORGINGS",
  "FORKLIFTS",
  "FRAGMENTATION TECHNOLOGY",
  "FREQUENCY CONVERTERS",
  "FRICTION MATERIALS",
  "FRIGATES",
  "FUEL CELLS",
  "FUEL SYSTEMS & EQPT",
  "FUEL TANKS",
  "FUEL TOTALIZERS",
  "FUELLING EQPT",
  "FUELS & OILS",
  "FUELS AND LUBRICANTS",
  "FUES",
  "FURNISHING & SUPPLIES",
  "FURNITURE",
  "FUSE BOXES: HOLDERS & ACCESSORIES",
  "FUZING SYSTEMS",
  "GAP CROSSING EQPT",
  "GAS SNIFFERS/DETECTORS",
  "GAS TURBINES",
  "GASES SAFETY PRODUCTS",
  "GASKET MATERIALS",
  "GAUGES & GAUGING EQPT",
  "GEARS & ASSEMBLIES",
  "GENERATING SYSTEMS",
  "GIMBAL SYSTEMS",
  "GLASS",
  "GLIDERS",
  "GLOBAL POSITIONING SYSTEMS",
  "GOGGLES",
  "GOVERNMENT",
  "GOVERNMENT DEPTS/ORGANISATIONS",
  "GRAPHITE",
  "GREASE",
  "GRINDERS/GRINDING MACHINES",
  "Ground Equipment",
  "GROUND HANDLING EQPT",
  "GROUND POWER EQPT",
  "GROUND SUPPORT EQPT",
  "GROUND SUPPORT EQUIPMENT MANUFACTURER",
  "GUIDANCE SYSTEMS",
  "GUIDED WEAPON CONTROL",
  "GUIDED WEAPONS",
  "GUNS",
  "GYROSCOPES",
  "HANDGUNS",
  "HANDSETS: INTERPHONE: RADIO",
  "HANGARS",
  "HARDENED BUILDINGS",
  "HARDWARE",
  "HARDWARE MANUFACTURER",
  "HARNESSES",
  "HAZARDOUS MATERIALS/EXPLOSIVES",
  "HAZARDOUS WASTE TREATMENT SERVICES",
  "HEAD MOUNTED DISPLAYS",
  "HEAD-UP DISPLAYS",
  "HEADSETS",
  "HEARING PROTECTION",
  "HEAT EXCHANGERS",
  "HEAT RESISTING MATERIALS",
  "HEAT SINKS",
  "HEAT TREATMENT",
  "HEATERS",
  "HELICOPTER ACCESSORIES",
  "HELICOPTER DESIGN AND MAINTENANCE",
  "HELICOPTERS",
  "HELIPORT",
  "HELMET-MOUNTED DISPLAYS",
  "HELMETS AND PROTECTIVE HEADGEARS",
  "HINGES",
  "HOISTS",
  "HONEYCOMB",
  "HONING",
  "HOOKS",
  "HORNS: ACOUSTIC",
  "HOSES AND FITTINGS",
  "HOT INSULATING MATERIAL",
  "HOT ISOSTATIC PRESSINGS",
  "HOUSINGS",
  "HOVERCRAFT",
  "HUB ASSEMBLIES",
  "HUMAN FACTORS ENGINEERING",
  "HYDRAULIC EQPT AND COMPONENTS",
  "HYDRAULIC FILTER",
  "ICE DETECTORS",
  "IDENTIFICATION DEVICES",
  "IFF SYSTEMS",
  "IGNITERS",
  "IGNITION SYSTEMS/STARTERS",
  "ILLUMINATION EQPT",
  "IMAGE ANALYSIS SYSTEMS",
  "IMAGE CAPTURE SYSTEMS & EQPT",
  "IMAGE INTENSIFICATION",
  "IMAGE TRANSMISSION SYSTEM",
  "IMPACT BLASTING",
  "IMPELLERS",
  "INCLINOMETERS",
  "INDICATOR INSTRUMENTS",
  "INDUCTORS",
  "INDUSTRY ENGAGEMENT",
  "INDUSTRY SCHEMES",
  "INERTIAL COMPONENTS & SYSTEMS",
  "INFANTRY WEAPONS",
  "INFLATION GEAR",
  "INFORMATION SECURITY SYSTEMS",
  "Information Security Systems",
  "INFORMATION SERVICES",
  "INFRA-RED AND NIGHT VISION",
  "INFRASTRUCTURE",
  "INHIBITORS: CORROSION",
  "INJECTION MOULDING",
  "INJECTION SYSTEMS",
  "INSERTS",
  "INSPECTION MATERIALS AND EQPT",
  "INSTRUMENT & PARTS: AIRCRAFT",
  "INSTRUMENT LANDING SYSTEMS",
  "instrumentation",
  "INSTRUMENTS BAROMETRIC",
  "INSTRUMENTS-ENGINE AND FUEL",
  "INSTRUMENTS-FLIGHT",
  "INSTRUMENTS-INDUSTRIAL",
  "INSTRUMENTS-NAVIGATION",
  "INSTRUMENTS: TESTING",
  "INSULATION",
  "INSURANCE",
  "INSURERS",
  "INTERCOMMUNICATIONS SYSTEMS",
  "INTERCOOLERS",
  "INTERIORS: AIRCRAFT",
  "INTERROGATORS: IFF",
  "INTRUSION DETECTION SYSTEMS",
  "INVENTORY SYSTEMS",
  "JACK PANEL ASSEMBLIES",
  "JACKS",
  "JIGS & FIXTURES",
  "JOINTING AND SEALING COMPOUNDS",
  "JUMPERS",
  "LABELS & LABELING EQPT",
  "LABORATORY EQPT",
  "LAMINATIONS & LAMINATING EQPT",
  "LAMPS & ASSEMBLIES",
  "LANDING GEAR",
  "LANDING GUIDANCE SYSTEMS",
  "LANDING SYSTEMS",
  "LASER DETECTION",
  "LASER RANGE -FINDERS",
  "LASER TARGETING PODS",
  "LATCHES",
  "LATHES",
  "LAUNCHERS/LAUNCHING EQPT",
  "LDP",
  "LEAD & ALLOYS",
  "LEAK DETECTORS",
  "LEASING: FINANCE & SALES",
  "LEATHER & ACCESSORIES",
  "LENSES",
  "LIFE RAFTS",
  "LIFE SUPPORT SYSTEMS",
  "LIFTS/LIFTING EQPT",
  "LIGHT ARMOURED VEHICLES",
  "LIGHT NAVAL GUN SYSTEMS",
  "LIGHTING EQPT & SYSTEMS",
  "LINER MATERIALS",
  "LIQUID GASES",
  "LOCAL AREA NETWORK",
  "LOCATORS",
  "LOCKS & LOCKERS",
  "LOGISTIC SUPPORT",
  "LOUDSPEAKERS",
  "LUBRICANTS & LUBRICATION EQPT",
  "LUGS: CABLE",
  "MACHINE PARTS: PRECISION",
  "MACHINE TOOLS",
  "MACHINERY: CONTROLLED",
  "MACHINING SYSTEMS",
  "MAETRIALS AND COMPONENTS",
  "MAGNESIUM ALLOYS AND STRUCTURES",
  "MAGNETOMETERS",
  "MAGNETOS & PARTS",
  "MAGNETRONS",
  "MAIN BATTLE TANKS",
  "Maintenance",
  "MAINTENANCE AND OVERHAUL",
  "MANAGEMENT SYSTEMS",
  "MANIFOLDS/DUCTS",
  "MANOMETERS",
  "MANUALS",
  "MAPS: CHARTS & MAPPING EQPT",
  "MARINE EQPT-AIRCRAFT",
  "MARINE MILITARY CRAFT",
  "MARINE PROPULSION VEHICLES",
  "MARKERS",
  "MARKING EQPT",
  "MASKS",
  "MAST MOUNTED SIGHTS",
  "Material and Components",
  "MATERIALS AND STRUCTURES",
  "MATERIALS HANDLING EQPT",
  "MATERIALS MANUFACTURER",
  "MEASURING EQPT",
  "MECHANICAL COMPONENTS",
  "MEDICAL EQPT & SUPPLIES",
  "MEGAPHONES",
  "METAL BONDING/JOINING",
  "METAL DETECTORS",
  "METAL FABRICATIONS",
  "METAL FINISHING/POLISHING",
  "METAL TREATMENT",
  "METAL WORKING TOOLS",
  "METALLIZING EQPT",
  "METALS & ALLOYS",
  "METEOROLOGICAL EQPT",
  "METERS",
  "MICRO-CIRCUITRY",
  "MICROMETERS",
  "MICROPHONES",
  "MICROWAVE EQPT",
  "MILITARY",
  "MILITARY BRIDGES",
  "MILITARY MACHINERY MFRS",
  "MILITARY MESSAGING SYSTEMS",
  "MILITARY VEHICLES",
  "MINE AND UXO DETECTION EQPT",
  "MINE CLEARING TECHNOLOGY",
  "MINE COUNTERMEASURES EQPT",
  "MINE COUNTERMEASURES VESSELS",
  "MISSILE LAUNCHERS",
  "MISSILES",
  "MOBILE CATERING SYSTEMS",
  "MOBILE FIELD HOSPITALS",
  "MOBILE MEDICAL SYSTEMS",
  "MOBILE SYSTEMS",
  "MODERNISATION AND CONVERSION",
  "MODIFICATIONS",
  "MOUNTINGS-ANTI-VIBRATION",
  "MRO",
  "MULTIMEDIA APPLICATIONS",
  "NAVAL SHIPBUILDING",
  "NAVAL CRAFT",
  "NAVAL GUNS/MISSILES",
  "NAVAL MINESWEEPING EQPT",
  "NAVAL SCHOOLS",
  "Navigation",
  "NAVIGATION AIDS",
  "NAVIGATION SYSTEMS",
  "NBC CLOTHING",
  "NBC PROTECTIVE EQPT",
  "NETTING",
  "NICKEL ALLOYS",
  "NIGHT VISION DEVICES",
  "NOISE MEASURING EQPT",
  "NOISE MONITORING SYSTEMS",
  "NOISE REDUCTION",
  "NONABRASIVE FINISHING EQPT",
  "NONDESTRUCTIVE TESTING EQPT",
  "NOSE CONES",
  "NOZZLES",
  "NUCLEAR AND INDUSTRIAL TECHNOLOGY",
  "NUCLEAR HARDENING",
  "NUCLEAR: BIOLOGICL & CHEMICAL DEFENCE",
  "NUMERIC CONTROL EQPT",
  "NUT PLATES",
  "NUTS",
  "ODOUR CONTROL CHEMICALS",
  "OIL COOLERS",
  "OIL TANKS: ENGINE",
  "OILS & OILING EQPT",
  "OPERATIONS RESEARCH",
  "OPTICAL EQPT & SYSTEMS",
  "ORBITAL MANEOUVERING SYSTEMS",
  "ORDNANCE",
  "ORGANISATIONS",
  "ORINGS/GLANDS",
  "OSCILLATORS",
  "OSCILLOGRAPHS & OSCILLOSCOPES",
  "OUTBOARD MOTORS",
  "OVENS: INDUSTRIAL",
  "Overhauls",
  "OVERHAULS of MACHINERY: SYSTEMS",
  "OXIDIZERS",
  "PACKAGING AND PACKAGING MATERIALS",
  "PAINTS & APPLICATION EQPT",
  "PALLETS: AIRCRAFT",
  "PANELS",
  "PAPER & PAPER PRODUCTS",
  "PARACHUTES & AIR DROP SYSTEMS",
  "PARAMILITARY",
  "PARTS SUPPLIERS",
  "PAYLOAD INTEGRATION",
  "PCM EQPT",
  "PENETRANTS",
  "PERSONAL GEAR",
  "PERSONNEL PROTECTION-CLOTHING",
  "PERSONNEL SECURITY",
  "PEST CONTROL/EXAMINATION",
  "PHASE SHIFTERS: MICROWAVE",
  "PHOSPHATE COATINGS",
  "PHOTOELECTRIC EQPT",
  "PHOTOGRAPHIC EQPT",
  "PILLOWBLOCKS",
  "PILOT SUPPLIES",
  "PINS",
  "PISTONS AND PISTON RINGS",
  "PIVOTS",
  "PLASMA SPRAYING",
  "PLASTICS: FABRICATORS",
  "PLASTICS: MATERIALS",
  "PLATFORM MANAGEMENT SYSTEMS",
  "PLATFORMS: GYRO-STABILIZED",
  "PLATFORMS: WORK",
  "PLATING",
  "PLATINUM",
  "PLENUMS",
  "PNEUMATIC SYSTEMS EQPT",
  "POL",
  "POLICE",
  "POLICE EQPT",
  "POLISHING & BUFFING EQPT",
  "POLLUTION CONTROL",
  "POTENTIOMETERS",
  "POWDER COATINGS & METALLURGY",
  "POWER EQPT & POWER PACKS",
  "Power generator",
  "POWER SUPPLIES: UNINTERRUPTIBLE",
  "PREAMPLIFIERS",
  "PRECISION MACHINED PARTS",
  "PREHEATERS",
  "PRESELECTORS",
  "PRESERVATION",
  "PRESSES",
  "PRESSURE BOOSTERS",
  "PRESSURE GAUGES",
  "PRESSURE LIMITING CONTROLS/EQPT",
  "PRESSURE SUITS",
  "PRESSURE VESSELS",
  "PRESSURIZATION SYSTEMS",
  "PRINTED CIRCUITS",
  "PRISMS",
  "PROBES: TEST",
  "PROCESS CONTROL EQPT",
  "PRODUCTION PLANT AND EQPT",
  "PROFESSIONAL BODIES & ASSOCIATIONS",
  "PROGRAMMING",
  "PROJECT MANAGEMENT",
  "PROJECTION EQPT",
  "PROPELLANTS",
  "PROPELLERS",
  "PROPULSION SYSTEMS",
  "PROTECTION -BODY",
  "PROTECTION EQPT-FIRE: SMOKE: NBC",
  "PROTECTIVE CLOTHING",
  "PROTOTYPING",
  "PROXIMITY WARNING INDICATOR",
  "PUBLIC ADDRESS SYSTEMS",
  "PUBLICATIONS",
  "PULLEYS",
  "PULSATION DAMPENERS",
  "PUMPS",
  "PUNCHING EQPT",
  "PURIFICATION EQPT",
  "PUSH-PULL CONTROL ASSEMBLY",
  "PYLONS",
  "PYROMETERS",
  "PYROTECHNICS",
  "QUALITY ASSURANCE/CONTROL",
  "QUARDRANTS: ENGINE CONTROL",
  "RACKS: AVIONIC & ELECTRONIC EQPT",
  "Radars",
  "RADAR EQPT",
  "Radar Equipment",
  "RADAR REFLECTORS",
  "RADAR WARNING SYSTEMS",
  "RADIATORS AND COOLERS",
  "RADIO COMMUNICATIONS EQPT",
  "RADIO FREQ FILTERS",
  "RADIO FREQ MANAGEMENT SYSTEMS",
  "RADIO RECEIVERS",
  "RADIOGRAPHIC EQPT",
  "RADIOMETERS",
  "RADIOPHONE",
  "RADOMES",
  "RAIN PROTECTION SYSTEMS & EQPT",
  "RAMPS",
  "RANGEFINDING EQPT",
  "Raw materials",
  "READOUT INSTRUMENTS",
  "RECEIVERS",
  "RECHARGER EQPT",
  "RECONNAISSANCE EQPT",
  "RECORDING EQPT",
  "RECOVERY AND RESCUE EQPT",
  "RECRUITMENT",
  "RECTIFIERS",
  "REDUCERS: SPEED",
  "REELS",
  "REENTRY VEHICLES/SYSTEMS",
  "REFLECTIVE MATERIALS",
  "REFLECTORS",
  "REFRACTORY MATERIALS",
  "REFRIGERATING SYSTEMS",
  "REFUELLING EQPT",
  "REFURBISHMENT: AIRCRAFT",
  "REGULATING AGENCIES",
  "REGULATING EQPT",
  "RELAYS",
  "RELEASE AGENTS",
  "RELEASE MECHANISMS",
  "REMOTE POSITIONING SYSTEMS",
  "REMOTE MANIPULATORS",
  "REMOTE SENSING SYSTEMS",
  "REMOTELY PILOTED VEHICLES",
  "Repairs",
  "REPAIRS: MAINTENANCE AND OVERHAUL",
  "REPEATERS",
  "RESCUE/FIREFIGHTING EQPT",
  "RESEARCH ROCKETS",
  "RESEARCH/CONSULTING SERVICES",
  "RESERVOIRS",
  "RESINS",
  "RESISTORS",
  "RESOLVERS",
  "RESPIRATORS",
  "RESTRAINTS",
  "RESTRICTORS",
  "RETROFIT: ENGINE",
  "REVERSERS: THRUST",
  "REWINDING SERVICES",
  "RHEOSTATS",
  "RINGS",
  "RIVETS & RIVETING EQPT",
  "ROBOTIC PARTS & EQPT",
  "ROBOTS: INDUSTRIAL",
  "ROCKET LAUNCHERS",
  "ROCKET MOTORS & VEHICLES",
  "ROLL FORMING MACHINES",
  "ROLLERS",
  "ROPES",
  "ROTARY JOINTS: WAVEGUIDE",
  "ROTORS",
  "ROUTING MACHINERY",
  "RUBBER PRODUCTS/POLYMERS",
  "RUBBER STOCKS",
  "RUBIDIUM",
  "RUNWAY MAINTENANCE & REPAIR",
  "SAFES & SECURE CONTAINERS",
  "SAFETY AND LIFE-SAVING EQPT",
  "Safety equipment",
  "SALVAGE & RETRIEVAL",
  "SAND BLASTING",
  "SANDING EQPT",
  "SANDWICH STRUCTURES",
  "SANITATION SYSTEMS & PRODUCTS",
  "SATELLITE GROUND SATION",
  "SATELLITE-BASED SYSTEMS",
  "SCAFFOLDING",
  "SCALES",
  "SCANNERS",
  "SCHOOLS: FLIGHT",
  "SCRAMBLERS",
  "SCREENING: WIRE",
  "SCREENS",
  "SCREWS",
  "Seals",
  "SEALS & SEALANTS",
  "SEAT BELTS",
  "SEATS",
  "SEATS-EJECTION",
  "SECURE COMMUNICATIONS",
  "SECURITY CONSULTANTS",
  "SECURITY EQPT SYSTEMS",
  "SEMICONDUCTOR COMPONENTS",
  "Semiconductors",
  "SENSORS AND TRANSDUCERS",
  "SENSORS: SECURITY",
  "SEPARATION SYSTEMS",
  "SERVO MECHANISM",
  "SERVO SYSTEMS AND ROBOTICS",
  "SERVOACTUATORS: RUDDERS",
  "SERVOVALVES",
  "SETS: RIVET",
  "SEXTANTS: OPTICAL",
  "SHACKLES",
  "SHAFTS & SHAFT ASSEMBLIES",
  "SHAKERS",
  "SHAPING EQPT",
  "SHEARING EQPT",
  "SHEET METAL COMPONENTS",
  "SHELTERS",
  "SHIBUILDING",
  "SHIELDS/SHIELDING",
  "SHIMS",
  "SHIP CONVERSION: REFIT: REPAIR",
  "SHIPBOARD GUN MOUNTS",
  "SHOCK ABSORBERS/MOUNTS",
  "SHOT PEENING",
  "SHREDDERS: SOLID WASTE",
  "SHRINK-WRAPPING",
  "SIGHTS",
  "SIGNATURE REDUCTION MATERIALS",
  "SILICA GEL",
  "SILICONS",
  "SILVER PLATING EQPT",
  "SIMULATION SYSTEMS",
  "SIRENS: AUDIO WARNING",
  "SKIS: AIRCRAFT",
  "SLEEVING",
  "SLIDES",
  "SLINGS",
  "SLIPRINGS",
  "SMALL ARMS",
  "SMALL ARMS AMMUNITION",
  "SMOKE CANISTERS",
  "SNIFFERS",
  "SNIPER DETECTION SYSTEMS",
  "SNOW MELTING & REMOVAL EQPT",
  "SNUBBERS",
  "SOCKETS",
  "Software",
  "SOFTWARE AUTOMATION SYSTEMS",
  "SOFTWARE CONSULTANTS",
  "SOLAR ARRAYS & CELLS",
  "SOLAR REFLECTIVE COVERS",
  "SOLDIER SYSTEMS",
  "SOLDERING AND BRAZING MATERIALS",
  "SOLENOIDS",
  "SOLID STAE CIRCUITRY",
  "SOLVENTS",
  "SONAR SYSTEMS",
  "SOUND SUPPRESSORS",
  "SOUNPROOFING MATERIALS",
  "SPACE AND SATELLITES",
  "SPACE EQUIPMENT",
  "SPACE SUITS",
  "SPACECRAFT & SATELLITE PROCESSING",
  "SPACERS",
  "SPARE PARTS SUPPLY",
  "Spares",
  "SPARES FOR SHIPS AND SUBMARINES",
  "SPARK PLUGS",
  "SPEAKERS",
  "SPECIAL FORCES EQPT",
  "SPECIAL VEHICLES",
  "SPECTROMETERS",
  "SPECTROPHOTOMETERS",
  "SPILL CONTROL EQPT",
  "SPINNERS: PROPELLER",
  "SPLINES: BALL BEARING",
  "SPRAYING EQPT: AERIAL",
  "SPRINGS",
  "SPROCKETS",
  "SQUIBS",
  "STABILIZERS",
  "STALL WARNING SYSTEMS",
  "STAMPING EQPT",
  "STARTERS",
  "Static Frequency converters",
  "STATIC LINES",
  "STEALTH MATERIALS",
  "STEEL COMPONENTS AND STRUCTURES",
  "STEEL MATERIALS",
  "STEEL: TOOL & DIE",
  "STEERING SYSTEMS",
  "STENCILING",
  "STIFFNUTS",
  "STORAGE SYSTEMS",
  "STORES MANAGEMENT SYSTEMS",
  "STRAIGHTENING MACHINES",
  "STRAIN GAUGE EQPT",
  "STRAINERS",
  "STREAMERS: WARNING",
  "STRESS ANALYSIS EQPT",
  "STRIPPING",
  "STROBOSCOPES",
  "STRUCTURAL FABRICATIONS",
  "STRUTS",
  "STUDS",
  "SUBMARINES",
  "SUITS: PRESSURE",
  "SUMPS",
  "SUNSCREENS",
  "SUPERALLOYS",
  "SUPERCHARGERS",
  "SUPERCONDUCTING MATERIALS",
  "SUPPORT SERVICES",
  "SUPPRESSOR SYSTEMS: FLAME",
  "SUPPRESSORS: TRANSIENT VOLTAGE",
  "SURFACTANTS",
  "SURVELLANCE SYSTEMS",
  "SURVIVAL SYSTEMS & EQPT",
  "SWITCHES & SWITCHING SYSTEMS",
  "SYNCHRONIZERS: ENGINE",
  "SYNCHROS: RESOLVERS",
  "SYNTHESIZERS: FREQ",
  "SYSTEM INTEGRATION",
  "SYSTEMS ENGINEERING",
  "SYSTEMS ANALYSIS",
  "SYSTEMS CONFIGURATION",
  "SYSTEMS INTEGRATION",
  "SYSTEMS MANUFACTURER",
  "TACHOMETERS",
  "TANK TRUCKS",
  "TANKS",
  "TANTALUM",
  "TAPPING MACHINERY",
  "TAPS",
  "TARGETS",
  "TARGETS: AERIAL",
  "TARPAULINS",
  "TELECOMMUNICATIONS SERVICES",
  "TELECOMMUUNICATIONS",
  "TELEMETRY",
  "TELEPHONES",
  "TELESCOPES",
  "TELEVISION SYSTEMS & EQPT",
  "TEMPERATURE CONTROLS",
  "TENSION DEVICES",
  "TENTS",
  "TERMINAL BOARDS",
  "TERMINAL STRIPS",
  "TEST CELLS",
  "TEST CHAMBERS",
  "TEST EQPT",
  "TEST EQUIPMENT MANUFACTURER",
  "TEST FACILITIES-ENVIRONMENTAL",
  "TESTERS",
  "TETRA SYSTEMS",
  "Textiles",
  "THERMAL IMAGING",
  "THERMISTORS",
  "THERMOCCOUPLES & ACCESSSORIES",
  "THERMOELECTRIC DEVICES & MATERIALS",
  "THERMOSTATS",
  "THYRISTORS",
  "TIEDOWN EQPT",
  "TIMERS/TOMING SYSTEMS",
  "TIN",
  "TITANIUM & ALLOYS: COMPONENTS",
  "TOILET EQPT",
  "TOOLS & TOOLING",
  "TOOLS MANUFACTURER",
  "TORPEDOES",
  "TOWBARS",
  "TOWERS",
  "TOXIC WASTE",
  "TRACKED ARMOURED COMBAT VEHICLES",
  "TRACKING EQPT",
  "TRACKS FOR ARMOURED VEHICLES",
  "TRACKS: AIRCRAFT SEATING",
  "TRACTORS",
  "TRADE ASSOCIATIONS",
  "TRADE SHOW MANAGEMENT",
  "TRAILERS & TRANSPORTERS",
  "TRAILERS: SEMI-TRAILERS",
  "TRAINING AND TESTING EQPT",
  "TRAINING SERVICES",
  "TRAINING SYSTEMS",
  "TRANSCEIVERS",
  "TRANSDUCERS",
  "TRANSFORMERS",
  "TRANSISTOR POWER SOURCES",
  "TRANSISTORS",
  "TRANSLATION & INTERPRETING SERVICES",
  "TRANSMISSION",
  "TRANSMITTERS",
  "TRANSPARENCIES",
  "TRANSPONDERS",
  "TRANSPORTATION",
  "TRUCK MOUNTED SHELTERS",
  "TRUCKS",
  "TRUNKED RADIO SYSTEMS",
  "TUBES AND PIPES",
  "TUNGSTEN",
  "TURBINE DISCS",
  "Turbo alternators",
  "TURBOCHARGERS",
  "TURNING MACHINERY",
  "TURNSTILES",
  "TURNTABLES",
  "TURRETS",
  "TWINE",
  "TWISTERS: WIRE",
  "TYRES & TUBES",
  "ULTRALIGHTS",
  "ULTRASONIC CLEARING FLUIDS",
  "ULTRASONIC EQPT",
  "Underwater Systems",
  "UNDERWATER AND DIVING EQPT",
  "UNGUIDED ROCKET WEAPON SYSTEM",
  "UNIFORMS & WORK APPAREL",
  "UNIVERSAL JOINTS",
  "UNMANNED AERIAL VEHICLES",
  "UPHOLSTERY",
  "VACUM EQPT & SYSTEMS",
  "VALVES: SOLENOID",
  "VANADIUM",
  "VANES: TURBINE ENGINE",
  "VANS",
  "VAPOURIZERS",
  "VARNISH: ELECTRICLAL",
  "VEHICLES",
  "Venhicles",
  "VENTILATORS",
  "VIBRATION: ISOLATORS",
  "VIBRATION: TEST EQPT",
  "VIBRATORS",
  "VIDEO AND PHOTOGRAPHIC EQPT",
  "VIDEO RECORDER",
  "VISORS",
  "VOICE INTERACTIVE SYSTEMS",
  "VOLTAGE REGULATORS",
  "VOLTMETERS",
  "WARGAMING SIMULATION",
  "WARNING SYSTEMS/EQPT",
  "WARSHIP: SURFACE COMBATANT CRAFT",
  "WASH PRIMERS",
  "WASHERS",
  "WASHING EQPT",
  "WASTE REMOVAL SYSTEM",
  "WATCHES",
  "WATER CONDITIONING EQPT",
  "WATER PURIFICATION SYSTEMS",
  "WATER TREATMENT AND DESALINATION",
  "WATERJET EQPT",
  "WAVEGUIDES",
  "WEAPON AIMING SYSTEMS",
  "WEAPON LOCATING SYSTEMS",
  "WEAPON SUB-SYSTEMS",
  "WEAPON SYSTEMS",
  "WEATHER FORECASTING SYSTEMS",
  "WEIGHING EQPT",
  "WEIGHTING AND BALANCING EQPT",
  "WHEEL & BEARING PROTECTORS",
  "WHEELS & BRAKES",
  "WINCHES",
  "WIND SOCKS & CONES",
  "WIND TUNNEL MODELS",
  "WIND TUNNELS",
  "WINDOWS",
  "WINDSHIELDS",
  "WINGS",
  "WIRE & CABLE",
  "WIRE CLOTH",
  "WIRING SYSTEMS",
  "WIRING: PRINTED",
  "WOOD PRODUCTS AND VENEERS",
  "WORKSHOPS",
  "X-RAY EQPT",
  "YOKES",
  "ZIRCONIUM",
];

export { mainBusinessActivities, mainBusinessProducts };
