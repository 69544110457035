import React from 'react';

const ScheduleRow = ({ time, activity, location, speaker_presenter, attend, className, isParallel, index }) => {
    const rowBackgroundColor = index % 2 === 0 ? 'bg-primeinputbg' : 'bg-schedulerowbg';

    return (
        <div className={`py-2 px-4  ${className} ${rowBackgroundColor}`}>
            <div className="text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-2xl p-1 text-justify font-medium w-[10%]">{time}</div>
            <div className={`text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-2xl p-1 ${isParallel ? 'w-[39%]' : 'w-[65%]'} `}>{activity}</div>
            {isParallel ? (
                <>
                    <div className="text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-2xl p-1 w-[17%]">{speaker_presenter}</div>
                    <div className="text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-2xl p-1 w-[17%]">{location}</div>
                    <div className="text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-2xl p-1 w-[17%]">{attend}</div>
                </>
            ) : (
                <div className="text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-2xl p-1 w-[15%]">{location}</div>
            )}
        </div>
    );
};

export default ScheduleRow;
