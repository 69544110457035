import React, { useEffect, useRef } from 'react'
import { IoMdArrowRoundBack } from 'react-icons/io'
import CommonScheduleTable from './CommonScheduleTable'
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';

const scheduleData = [
    { time: "08:00-09:00", activity: "Welcome Tea", location: "Entrance Hall" },
    { time: "09:00-09:10", activity: "Welcome Address. Maj Gen (Dr) Ashok Kumar, VSM, Retd, DG CENJOWS", location: "Zorawar Hall" },
    { time: "09:10-09:25", activity: "Inaugural Address. Gen Anil Chauhan, PVSM, UYSM, AVSM, VSM, Chief of Defence Staff", location: "Zorawar Hall" },
    { time: "09:25-09:35", activity: "Keynote Address.", location: "Zorawar Hall" },
    { time: "09:35-09:45", activity: "Special Talk.", location: "Zorawar Hall" },
    { time: "09:35-09:45", activity: "Refreshments", location: "Banquet Hall" },
    { time: "10:20-10:40", activity: "Meeting A1", location: "Booths and Tables" },
    { time: "10:40-11:00", activity: "Meeting A2", location: "Booths and Tables" },
    { time: "11:00-11:20", activity: "Meeting A3", location: "Booths and Tables" },
    { time: "11:20-11:40", activity: "Meeting A4", location: "Booths and Tables" },
    { time: "11:40-12:00", activity: "Meeting A5", location: "Booths and Tables" },
    { time: "12:00-12:20", activity: "Meeting A6", location: "Booths and Tables" },
    { time: "12:20-12:40", activity: "Meeting A7", location: "Booths and Tables" },
    { time: "12:40-13:00", activity: "Meeting A8", location: "Booths and Tables" },
    { time: "13:00-14:00", activity: "Lunch", location: "" },
    { time: "14:00-14:20", activity: "Meeting A9", location: "Booths and Tables" },
    { time: "14:20-14:40", activity: "Meeting A10", location: "Booths and Tables" },
    { time: "14:40-15:00", activity: "Meeting A11", location: "Booths and Tables" },
    { time: "15:00-15:20", activity: "Meeting A12", location: "Booths and Tables" },
    { time: "15:20-15:40", activity: "Meeting A13", location: "Booths and Tables" },
    { time: "15:40-16:00", activity: "Meeting A14", location: "Booths and Tables" },
    { time: "16:00-16:20", activity: "Meeting A15", location: "Booths and Tables" },
];
const parallelProgramme1 = [
    { time: "08:00-09:00", activity: "--", speaker_presenter: "", location: "", attend: "" },
    { time: "09:00-09:10", activity: "--", speaker_presenter: "", location: "", attend: "" },
    { time: "09:10-09:25", activity: "--", speaker_presenter: "", location: "", attend: "" },
    { time: "09:25-09:35", activity: "--", speaker_presenter: "", location: "", attend: "" },
    { time: "09:35-09:45", activity: "--", speaker_presenter: "", location: "", attend: "" },
    { time: "09:35-09:45", activity: "--", speaker_presenter: "", location: "", attend: "" },
    { time: "10:20-10:40", activity: "Presentation", speaker_presenter: "Speaker name", location: "Zorawar Hall", attend: "Enroll free" },
    { time: "10:40-11:00", activity: "Workshop", speaker_presenter: "Speaker name", location: "Zorawar Hall", attend: "Enroll free" },
    { time: "11:00-11:20", activity: "Presentation", speaker_presenter: "Speaker name", location: "Zorawar Hall", attend: "Enroll free" },
    { time: "11:20-11:40", activity: "Workshop", speaker_presenter: "Speaker name", location: "Zorawar Hall", attend: "Enroll free" },
    { time: "11:40-12:00", activity: "Presentation", speaker_presenter: "Speaker name", location: "Zorawar Hall", attend: "Enroll free" },
    { time: "12:00-12:20", activity: "Workshop", speaker_presenter: "Speaker name", location: "Zorawar Hall", attend: "Enroll free" },
    { time: "12:20-12:40", activity: "Presentation", speaker_presenter: "Speaker name", location: "Zorawar Hall", attend: "Enroll free" },
    { time: "12:40-13:00", activity: "Workshop", speaker_presenter: "Speaker name", location: "Zorawar Hall", attend: "Enroll free" },
    { time: "13:00-14:00", activity: "Lunch", speaker_presenter: "", location: "Banquet", attend: "" },
    { time: "14:00-14:20", activity: "Presentation", speaker_presenter: "Speaker name", location: "Zorawar Hall", attend: "Enroll free" },
    { time: "14:20-14:40", activity: "Workshop", speaker_presenter: "Speaker name", location: "Zorawar Hall", attend: "Enroll free" },
    { time: "14:40-15:00", activity: "Presentation", speaker_presenter: "Speaker name", location: "Zorawar Hall", attend: "Enroll free" },
    { time: "15:00-15:20", activity: "Workshop", speaker_presenter: "Speaker name", location: "Zorawar Hall", attend: "Enroll free" },
    { time: "15:20-15:40", activity: "Presentation", speaker_presenter: "Speaker name", location: "Zorawar Hall", attend: "Enroll free" },
    { time: "15:40-16:00", activity: "Workshop", speaker_presenter: "Speaker name", location: "Zorawar Hall", attend: "Enroll free" },
    { time: "16:00-16:20", activity: "Presentation", speaker_presenter: "Speaker name", location: "Zorawar Hall", attend: "Enroll free" },
];
const parallelProgramme2 = [
    { time: "08:00-09:00", activity: "--", speaker_presenter: "", location: "", attend: "" },
    { time: "09:00-09:10", activity: "--", speaker_presenter: "", location: "", attend: "" },
    { time: "09:10-09:25", activity: "--", speaker_presenter: "", location: "", attend: "" },
    { time: "09:25-09:35", activity: "--", speaker_presenter: "", location: "", attend: "" },
    { time: "09:35-09:45", activity: "--", speaker_presenter: "", location: "", attend: "" },
    { time: "09:35-09:45", activity: "--", speaker_presenter: "", location: "", attend: "" },
    { time: "10:20-10:40", activity: "Presentation", speaker_presenter: "Speaker name", location: "Shamsher Hall", attend: "Enroll free" },
    { time: "10:40-11:00", activity: "Workshop", speaker_presenter: "Speaker name", location: "Shamsher Hall", attend: "Enroll free" },
    { time: "11:00-11:20", activity: "Presentation", speaker_presenter: "Speaker name", location: "Shamsher Hall", attend: "Enroll free" },
    { time: "11:20-11:40", activity: "Workshop", speaker_presenter: "Speaker name", location: "Shamsher Hall", attend: "Enroll free" },
    { time: "11:40-12:00", activity: "Presentation", speaker_presenter: "Speaker name", location: "Shamsher Hall", attend: "Enroll free" },
    { time: "12:00-12:20", activity: "Workshop", speaker_presenter: "Speaker name", location: "Shamsher Hall", attend: "Enroll free" },
    { time: "12:20-12:40", activity: "Presentation", speaker_presenter: "Speaker name", location: "Shamsher Hall", attend: "Enroll free" },
    { time: "12:40-13:00", activity: "Workshop", speaker_presenter: "Speaker name", location: "Shamsher Hall", attend: "Enroll free" },
    { time: "13:00-14:00", activity: "Lunch", speaker_presenter: "", location: "Banquet", attend: "" },
    { time: "14:00-14:20", activity: "Presentation", speaker_presenter: "Speaker name", location: "Shamsher Hall", attend: "Enroll free" },
    { time: "14:20-14:40", activity: "Workshop", speaker_presenter: "Speaker name", location: "Shamsher Hall", attend: "Enroll free" },
    { time: "14:40-15:00", activity: "Presentation", speaker_presenter: "Speaker name", location: "Shamsher Hall", attend: "Enroll free" },
    { time: "15:00-15:20", activity: "Workshop", speaker_presenter: "Speaker name", location: "Shamsher Hall", attend: "Enroll free" },
    { time: "15:20-15:40", activity: "Presentation", speaker_presenter: "Speaker name", location: "Shamsher Hall", attend: "Enroll free" },
    { time: "15:40-16:00", activity: "Workshop", speaker_presenter: "Speaker name", location: "Shamsher Hall", attend: "Enroll free" },
    { time: "16:00-16:20", activity: "Presentation", speaker_presenter: "Speaker name", location: "Shamsher Hall", attend: "Enroll free" },
];

const Day1Schedule = ({ closeModal }) => {

    return (
        <div className='rounded-xl space-y-2 lg:space-y-4 p-4 lg:p-8'>
            {/* <div className='px-4 font-semibold'>
                <IoMdArrowRoundBack size={20} onClick={closeModal} />
            </div> */}
            <div>
                <button onClick={closeModal} className="flex items-center gap-2 font-semibold text-xl rounded-full bg-footerblue text-white p-1 px-2 pr-3">
                    <FaArrowLeft size={20} /> Back
                </button>
            </div>
            <div className='flex flex-col gap-4'>
                <div className='px-4 font-bold text-lg lg:text-xl xl:text-2xl 2xl:text-3xl'>Schedule of Day 1 (28 NOVEMBER 2024)</div>
                <div className='flex flex-col gap-12'>
                    <CommonScheduleTable title="Main Programme - 28 NOVEMBER 2024" data={scheduleData} />
                    <CommonScheduleTable title="PARALLEL PROGRAM 1 (28 NOVEMBER 2024) - PRESENTATIONS AND WORKSHOPS IN ZORAWAR HALL" data={parallelProgramme1} isParallel />
                    <CommonScheduleTable title="PARALLEL PROGRAM 2 (28 NOVEMBER 2024) - PRESENTATIONS AND WORKSHOPS IN SHAMSHER HALL" data={parallelProgramme2} isParallel />
                </div>
            </div>
        </div>
    );
};

export default Day1Schedule
