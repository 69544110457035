const sponsor = {
    doItLater: " Save & Exit",
    submit: "Submit",
    noteBold: 'Note: ',
    noteLine: "You may complete the requirement from your profile page later.",
    whysponsor: "WHY SPONSOR?",
    whysponsorblue: "Two categories of Sponsorships are available.",
    whysponsorarray: [
        {
            number: "01",
            title: "Corporate Sponsorship",
            disc: "For individual companies",
            btn: "View Details",
        },
        {
            number: "02",
            title: "Association Sponsorship",
            disc: "For Industry Associations",
            btn: "View Details",
        },
    ],
    corporatesponsor: "Corporate Sponsorship",
    corporatedisc:
        "Corporate Sponsorship positions your company as a leader in the defence industry, reinforcing your commitment  to innovation and excellence. Sponsorships provide enhanced brand visibility, opportunity to engage participants  directly through workshops and presentations, product demonstrations at your own exhibition stand, and a  Competitive Edge.",
    corporatediscarray: [
        "Enhanced Brand Visibility. ",
        "High-Profile Exposure",
        "Prominent Placement of the company’s logo ",
        "Conduct a Workshop  ",
        "Exhibition Space ",
        "Branding Opportunity ",
        "Communication Opportunity",
    ],
    spofollo: "Following options are available:",
    platinum: "Platinum Sponsorship",
    platinumarray: [
        "a. One 20-min Workshop or Presentation opportunity in the parallel schedule in Ashoka Hall (cap 220).  ",
        "b. Company’s logo as Platinum Sponsor, on Event Banners, in all marketing collaterals, event website,  Newsletters and in the Event Guide.  ",
        "c. One Full page advt in the print and digital Event Guide.  ",
        "d. Video of all Central Talks will be provided after the event.  ",
        "e. One Premium 12 sqm furnished exhibition booth.",
    ],
    platinumpoptitle: "Corporate Platinum Sponsorship",
    platinumpopdisc:
        "You have purchased Corporate Platinum Sponsorship Plan. Please fill in the details below to fulfill your order:",
    platinumpoplabel1: "Workshop or Presentation title",
    platinumpoplabel2: "Speaker’s name",
    platinumuploadarray1: [
        {
            title: "Upload Presentation file if offering",
            upload: "Upload Presentation file Here",
        },
        { title: "Upload Speaker’s photo", upload: "Upload speaker’s photo Here" },
    ],
    platinumuploadarray2: [
        { title: "Upload Company’s logo", upload: "Upload Company’s logo Here" },
        {
            title: "Upload Full page advt in Event Guide",
            upload: "Upload  Full page advt in Event Guide Here",
        },
    ],
    platinumpoplabel4: "Fascia name for your exhibition booth",
    platinumpopbtn: "Purchase Corporate Platinum Sponsorship",
    bookbtn: "Book Sponsorship",
    gold: "Gold Sponsorship",
    goldarray: [
        "a. One 20-min Workshop or Presentation opportunity in the parallel schedule in Ashoka Hall (cap 220).  ",
        "b. Company’s logo as Gold Sponsor, on Event Banners, in all marketing collaterals, event website, Newsletters  and in the Event Guide.  ",
        "c. One Full page advt in the print and digital Event Guide.  ",
        "d. Video of all Central Talks will be provided after the event.  ",
        "e. One Pride 6 sqm furnished exhibition booth.",
    ],
    goldpoptitle: "Corporate Gold Sponsorship",
    goldpopdisc:
        "You have purchased Corporate Gold Sponsorship Plan. Please fill in the details below to fulfill your order:",
    goldpopbtn: "Purchase Corporate Gold Sponsorship",
    silverpoptitle: "Corporate Silver Sponsorship",
    silverpopdisc:
        "You have purchased Corporate Silver Sponsorship Plan. Please fill in the details below to fulfill your order:",
    silverpopbtn: "Purchase Corporate Silver Sponsorship",
    silver: "Silver Sponsorship",
    silverarray: [
        "a. One 20-min Workshop or Presentation opportunity in the parallel schedule in Talwar Hall (cap 60).  ",
        "b. Company’s logo as Silver Sponsor, on Event Banners, in all marketing collaterals, event website, Newsletters  and in the Event Guide.  ",
        "c. One Half page advt in the print and digital Event Guide.  ",
        "d. Video of all Central Talks will be provided after the event.  ",
        "e. One Eco 4 sqm furnished exhibition booth.",
    ],
    assosponsor: "Association Sponsorship",
    assodisc:
        "Association Sposorships offer Strategic Exposure and Branding, Brand Association, opportunity to showcase  member companies at a good discount, and great piublicity about your association amongst the target audience.",
    assodiscarray: [
        "Enhanced Value for Members ",
        "Exclusive Benefits and Discounts ",
        "Visibility and Influence",
        "Brand Exposure  ",
        "Exhibition Space",
    ],
    assopoptitle: "Strategic Association Sponsorship",
    assopopdisc:
        "You have purchased Strategic Association Sponsorship Plan. Please fill in the details below to fulfill your order:",
    assouploadarray1: [
        {
            title: "Upload Association’s logo",
            disc: "Upload Association’s logo Here",
        },
        {
            title: "Upload Full page advt in Event Guide",
            disc: "Upload  Full page advt in Event Guide Here",
            size: "(213mm W x 277mm H)",
        },
    ],
    assouploadarray2: [
        { title: "Upload Speaker’s photo", disc: "Upload speaker’s photo Here" },
        {
            title: "Upload Presentation file if offering",
            disc: "Upload Presentation file Here",
        },
    ],
    assolabel1: "Speaker’s short biodata",
    assoMiniPoint1: "Workshop or Presentation title",
    straMiniPoint1: "Presentation Topic",
    assoMiniPoint2: "Speaker’s name",
    assoUploadTitle1: "Upload Presentation file (If offering for download)",
    assoUploadTitle1Disc: "Upload Presentation file here",
    assoUploadTitle1DiscSize: "(PDF only, max 25 mb)",
    assoUploadTitle2: "Upload Speaker’s photo",
    assoUploadTitle2Disc: "Upload speaker’s photo here",
    assoUploadTitle2DiscSize: "(300 x 300, high-res JPG, PNG,  max 1 mb)",
    assoUploadTitle3: "Upload Company’s logo",
    assoUploadTitle3Disc: "Upload Company’s logo here",
    assoUploadTitle3DiscSize: "(16:9 ratio, high~ res JPG, PNG max 25 mb)",
    assoUploadTitle4: "Upload Full page advt in Event Guide",
    assoUploadTitle4Disc: "Upload  Full page advt in Event Guide here",
    assoUploadTitle4DiscSize:
        "(213mm W x 277mm H, High res PDF only. See also mechanical data)",
    strategicUploadTitle1: "Upload Association’s logo",
    strategicUploadTitle1Disc: "Upload Association’s logo here",
    strategicUploadTitle1DiscSize: "(16:9 ratio, high~ res JPG, PNG max 25 mb)",
    strategicUploadTitle2: "Upload Full page advt in Event Guide",
    strategicUploadTitle2Disc: "Upload  Full page advt in Event Guide here",
    strategicUploadTitle2DiscSize:
        "(213mm W x 277mm H, High res PDF only. See also mechanical data)",
    strategicUploadTitle3: "Upload Speaker’s photo",
    strategicUploadTitle3Disc: "Upload speaker’s photo here",
    strategicUploadTitle3DiscSize: "(300 x 300, high-res JPG, PNG,  max 1 mb)",
    strategicUploadTitle4: "Upload Presentation file (If offering for download)",
    strategicUploadTitle5: "Upload Presentation file cover image",
    strategicUploadTitle4Disc: "Upload Presentation file here",
    strategicUploadTitle5Disc: "Upload Presentation file cover image here",
    strategicUploadTitle4DiscSize: "(PDF only, max 25 mb)",
    // strategicUploadTitle4DiscSize: "(PDF only, max 25 mb)",
    assolabel2: "Fascia name for your exhibition booth",
    assopopbtn: "Purchase Strategic Association Sponsorship",
    strategic: " Strategic Assn Sponsorship",
    strategicarray: [
        "a. 50 member companies from the Association get 10-meeting Business Meetings Package for one participant at  30% discount.",
        "b. Assn's logo as Strategic Assn Sponsor, on Event Banners, in all marketing collaterals, event website,  Newsletters and in the Event Guide. ",
        "c. One full page advt in the print and digital Event Guide.  ",
        "d. Video of all Central Talks will be provided after the event.",
        "e. One 3x2m furnished exhibition booth will be provided to the Assn",
    ],
    innopoptitle: "Innovation Association Sponsorship",
    innopopdisc:
        "You have purchased Innovation Association Sponsorship Plan. Please fill in the details below to fulfill your order:",
    innopopbtn: "Purchase Innovation Association Sponsorship",
    innovation: "Innovation Assn Sponsorship",
    innovationarray: [
        "a. 40 member companies from the Association get 10-meeting Business Meetings Package for one participant at  20% discount.",
        "b. Assn's logo as Innovation Assn Sponsor, on Event Banners, in all marketing collaterals, event website,  Newsletters and in the Event Guide.",
        "c. One full page advt in the print and digital Event Guide.",
        "d. Video of all Central Talks will be provided after the event.",
        "e. One Eco 4sqm furnished exhibition booth will be provided to the Assn",
    ],
    technology: "Technology Assn Sponsorship",
    technologyarray: [
        "a. 30 member companies from the Association get 10-meeting Business Meetings Package for one participant at  15% discount.",
        "b. Assn's logo as Innovation Assn Sponsor, on Event Banners, in all marketing collaterals, event website,  Newsletters and in the Event Guide.",
        "c. One half page advt in the print and digital Event Guide.",
        "d. Video of all Central Talks will be provided after the event.",
        "e. One Eco 4sqm furnished exhibition booth will be provided to the Assn.  Book Sponsorship",
    ],
    techpoptitle: "Technology Association Sponsorship",
    techpopdisc:
        "You have purchased Technology Association Sponsorship Plan. Please fill in the details below to fulfill your order:",
    techpopbtn: "Purchase Technology Association Sponsorship",
}

export default sponsor