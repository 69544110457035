const venue = {
    locations: [
        { name: "Zorawar Hall For Inaugural & Closing Sessions", desc: "Central Talks, Flash presentations and Closing Session", },
        { name: "Ashoka Hall", desc: "For presentations & worshops in parallel sessions  " },
        { name: "Talwar Hall", desc: "For presentations and workshops in parallel sessions" },
        { name: "Mayur Conference Room", desc: "For presentations and worshops in parallel sessions" },
        { name: "Reception Area", desc: "For B2B and B2G Meetings, Exhibiton" },
        { name: "Exhibition Hall 2 (Lower)", desc: "For B2G and B2B meetings" },
        { name: "Exhibition Hall 1 (Upper)", desc: "For B2G and B2B meetings" },
        { name: "Banquet Dining", desc: "For lunch and refreshment" },
        { name: "Foyer", desc: "For Registration, Welcome Tea & Mid-day Refreshments" },
    ],
}
export default venue