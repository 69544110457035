// roles.js
const roles = {
  PRIME_CONTRACTOR: "Prime Contractors/OEMs",
  MANUFACTURER: "Manufacturers & Suppliers",
  KEY_GOVT_OFFICER: "Key Govt/ Services Officer",
  INVESTOR: "Investors",
  EVENT_PARTNER: "Event Partners",
  CASUAL_VISITOR: "Casual Visitors",
  STARTUP: "Start-ups",
  COMPANY_LISTING: "Company Listing",
};

export default roles;
